<template>
  <div>
    <div class="mid_box">
      <img
        style="width: 15px"
        src="../assets/images/Examine_img/back.png"
        alt=""
        @click="toBack"
      />
      <span
        style="
          margin-left: 5px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #4f536c;
        "
        @click="toBack"
        >返回</span
      >
      <img style="width: 25px" src="../assets/images/Examine_img/Stat...png" alt="" />
      <span
        style="
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #63697e;
        "
        >查看统计表</span
      >
    </div>
    <div class="Company_item_box">
      <div
        class="Company_item"
        :class="{ active: curIndex == index }"
        v-for="(item, index) in CompanyList"
        :key="index"
        @click="chooseItem(index, item)"
      >
        <span style="display: flex; align-items: center">
          <img
            v-if="curIndex == index"
            style="width: 15px"
            src="../assets/images/Examine_img/NoChoose.png"
            alt=""
          />
          <img
            v-else
            style="width: 15px"
            src="../assets/images/Examine_img/choose.png"
            alt=""
          />
          <span
            style="
              margin-left: 5px;
              font-size: 14px;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            >{{ item.compName }}</span
          >
        </span>
        <div class="percent" :class="{ active: curIndex == index }">
          {{ item.completePercent }}
        </div>
        <span style="margin-left: 5px; font-size: 14px">已完成:</span
        ><span style="margin-left: 5px; font-size: 14px">{{ item.completeCount }}</span>
        <span style="margin-left: 19px; font-size: 14px">未完成:</span
        ><span style="margin-left: 5px; font-size: 14px">{{ item.noCompleteCount }}</span>
      </div>
    </div>
    <el-table
      :data="tableData2.slice(start, end)"
      style="width: 100%; margin-top: 20px"
      :height="elementHeight"
      :header-cell-style="{ background: '#E8EBF4', color: '#000000' }"
      border
      ref="tableData"
      id="table"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="questionRemark" label="问题"> </el-table-column>
      <el-table-column label="完成情况">
        <template slot-scope="scope">
          <span class="status" v-show="scope.row.isComplete === 1"
            ><div class="statusbox1"></div>
            已完成</span
          >

          <span class="status" v-show="scope.row.isComplete === 0"
            ><div style="background: #f2729c" class="statusbox"></div>
            未完成</span
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top:10px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage1"
      :page-sizes="[10, 20, 40]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData2.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { get } from "../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      tableData2: [],
      CompanyList: [],
      curIndex: null,
      currentPage1: "",
      start: 0,
      end: 10,
      curSize: 10,
    };
  },
  created() {
    this.getCompany();
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    async getCompany() {
      await get("/api/InOutAuditReport/GetCompReport").then((res) => {
        this.CompanyList = res.data;
        this.chooseItem(0, this.CompanyList[0]);
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.currentPage1 = e;
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    chooseItem(index, item) {
      get("/api/InOutAuditReport/GetReportByCompId?CompId=" + item.compId).then((res) => {
        this.tableData2 = res.data;
      });
      this.curIndex = index;
    },
    getElementHeight() {
       this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 190)
          console.log(this.elementHeight);
          
      })
    },
  },
};
</script>

<style scoped>
.mid_box {
  margin: 10px 10px 10px 10px;
  display: flex;
  flex: auto;
  align-items: center;
}
.Company_item_box {
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding-bottom: 9px;
}
.Company_item {
  width: 238px;
  height: 100px;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 3px 0px 0px rgba(214, 214, 214, 0.46);
  border-radius: 4px;
  margin: 0px 10px 0px 10px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #83868d;
  padding-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding-top: 11px;
}
.percent {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  white-space: nowrap;
  color: #4b526b;
  margin-top: 10px;
}
.statusbox {
  margin: 5px;
  width: 10px;
  height: 10px;
}
.status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statusbox1 {
  margin: 5px;
  width: 10px;
  height: 10px;
  background: url(../assets/images/Examine_img/ok.png);
  background-size: 100%;
}
.active {
  background: #5b79ae;
  color: #ffffff;
}

/* // ::-webkit-scrollbar {


//   height: 8px;
//   background-color: #aa1010;
// } */
::-webkit-scrollbar {
  margin-top: 3px;
  height: 10px;
  width: 0 !important;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
  background-color: rgb(218, 216, 216);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgb(92, 90, 90);
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
}
</style>
